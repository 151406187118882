import React from 'react';

import Fontbox from './fontbox';
import AwardsBox from './awardsbox';


const RightBar = () => (
  <div className="rightbar">
    <Fontbox />
    <AwardsBox />
  </div>
)

export default RightBar