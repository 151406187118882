import React from "react";
import { Link } from "gatsby"
import { Container, Row, Col } from 'react-bootstrap';
import './layout.css';

import a3Logo from '../images/a3i_footer_logo.png'

const Footer = () => (
  <Container className="footer" fluid={true}>
    <Row>
      <Col lg={{span: 6, offset: 1}} md={{span: 6, offset: 1}}>
        <p>
          NOTICE: Michael Mueller, Doris Candito, and Wayne Stewart are Registered Representatives of Coastal Equities, Inc. 
          and Investment Advisory Representatives of Coastal Investment Advisors, Inc. Neither Coastal Equities, Inc. 
          nor Coastal Investment Advisors, Inc. is affiliated with Coastal Advisory Group. Investment Advisory Services 
          are offered through Coastal Investment Advisors, Inc., and securities are offered through Coastal Equities, Inc., 
          Member <a rel="noopener noreferrer" href="http://www.finra.org/" target="_blank">FINRA</a>/<a rel="noopener noreferrer" href="http://www.sipc.com/" target="_blank">SIPC</a>, 1201 N. Orange St., Suite 729, Wilmington, DE 19801.
        </p>
      </Col>
      <Col md={4} className="footer--links">
        <div>
          <Link to="/">Home</Link> &nbsp; | &nbsp;
          <Link to='/contact'>Contact Us</Link> &nbsp; | &nbsp;
          <a href='/ADVII-updated.pdf' rel="noopener noreferrer" target="_blank">ADV II-B</a> &nbsp; | &nbsp;
          <a href='https://brokercheck.finra.org/' rel="noopener noreferrer" target="_blank">FINRA's BrokerCheck</a>
        </div>
        <div>
          <a href='https://www.sec.gov/' rel="noopener noreferrer" target="_blank">SEC</a> &nbsp; | &nbsp;
          <a href='/privacy-policy.pdf' rel="noopener noreferrer" target="_blank">Privacy Policy</a> &nbsp; | &nbsp;
          <Link to='/tos'>Terms of Use</Link>
        </div>
      </Col>

    </Row>
        <hr style={{borderTop: '1px solid white'}}/>
    <Row>
      <Col lg={{span: 4, offset: 4}} style={{textAlign: 'center'}}>
      <a className="poweredBy" href="https://a3illc.com" target="_blank" rel="noopener noreferrer">
        <p style={{display: 'inline-block'}}>Powered By</p>
        <img className="footer--power" src={a3Logo} />
        <p style={{display: 'inline-block'}}>
          © {new Date().getFullYear()}
        </p>
      </a>
      </Col>{/* 
      <Col style={{textAlign: 'center'}}>
        <Link className="footer--sitemap" to="/site-map">Site Map</Link>
      </Col> */}
    </Row>
  </Container>
)

export default Footer
