import React from 'react';
import Icon from '@material-ui/core/Icon';

const Fontbox = () => {
  const [currentFont, setCurrentFont] = React.useState(1);

  const increaseFontSize = () => {
    if(currentFont >= 1.5) return null
    document.body.style.fontSize = `${currentFont + 0.25}em`
    setCurrentFont(currentFont + 0.25);
  }

  const decreaseFontSize = () => {
    if(currentFont <= 0.5) return null
    document.body.style.fontSize = `${currentFont - 0.25}em`
    setCurrentFont(currentFont - 0.25);
  }

  const resetFontSize = () => {
    setCurrentFont(1);
    document.body.style.fontSize = '1em';
  }
  
  return (
    <>
      <div className="fontbox" style={styles.fontbox}>
        <h4>Font Size</h4>
        <Icon onClick={increaseFontSize} className={'fa fa-plus-circle fontChanger'} fontSize="large" />
        <Icon onClick={resetFontSize} className={'fa fa-redo fontChanger'} fontSize="large" />
        <Icon onClick={decreaseFontSize} className={'fa fa-minus-circle fontChanger'} fontSize="large" />
      </div>
    </>
  )
}
let styles = {
  fontbox: {
    width: '100%',
    backgroundColor: '#bdb7a1',
    boxShadow: '3px 3px 8px rgba(0,0,0,0.5)',
    margin: '0 10px 10px 10px',
    padding: '20px 0',
    display: 'inline-block',
    verticalAlign: 'top',
    textAlign: 'center'
  },
}

export default Fontbox